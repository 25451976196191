import * as React from 'react';
import { useFormikContext } from 'formik';
import { BookingAvailabilityState, CreateOrEditBookingForm, Permit } from '../types';
import { Button, FormGroup, Icon, Input, Label, Spacer } from '@autopay.io/style';
import { convertSecondsToTime, DURATION_ONE_DAY, DURATION_THREE_DAYS, DURATION_TWO_DAYS, SECONDS_IN_HOUR } from '../../../utils';
import moment = require('moment');
import { FormikDatePicker, FormikErrorMessage } from '@autopay.io/style/lib/formik';
import SettingsPopup from './SettingsPopup';
import { useEffect } from 'react';

interface BookingEndsSelectorProps {
    permit: Permit;
    onDurationPresetEdit: (durations: number[]) => void;
    availability: BookingAvailabilityState;
    fetchAvailability: (date: string, clientId: string) => void;
}

export const BookingEndsSelector = (props: BookingEndsSelectorProps) => {
    const formik = useFormikContext<CreateOrEditBookingForm>();
    const [showDurationPopup, setShowDurationPopup] = React.useState<boolean>(false);
    const durationsButtons = [props.permit.durations[0] ?? DURATION_ONE_DAY, props.permit.durations[1] ?? DURATION_TWO_DAYS, props.permit.durations[2] ?? DURATION_THREE_DAYS];

    useEffect(() => {
        const newDate = moment(formik.values.validFrom).add(1, 'day').endOf('day');

        if (props.availability && props.availability !== 'ERROR' && props.availability.validTo
            && moment(props.availability.validTo).isBefore(newDate, 'minute')) {
            formik.setFieldValue('validTo', new Date(props.availability.validTo));
        } else if (formik.values.endType === 'SELECTED' && moment(formik.values.validTo).isBefore(formik.values.validFrom, 'minute')) {
            formik.setFieldValue('validTo', newDate.toDate());
        } else if (formik.values.endType && formik.values.endType !== 'SELECTED') {
            formik.setFieldValue('validTo', moment(formik.values.validFrom).add(formik.values.duration, 'second').toDate());
        } else if (formik.values.endType === 'SELECTED' && moment(formik.values.validTo).isSame(formik.values.validFrom, 'minute')) {
            formik.setFieldValue('validTo', newDate.toDate());
        } else if (
            formik.values.endType === 'SELECTED' && formik.values.type === 'ENTRY' &&
            moment(formik.values.validTo).hour() === 0 && moment(formik.values.validTo).minute() === 0
        ) {
            formik.setFieldValue('validTo', moment(formik.values.validTo).set('hour', 23).set('minute', 59).toDate());
        }

    }, [formik.values.validFrom, formik.values.endType]);

    useEffect(() => {
        if (formik.values.validFrom) { // optimization
            props.fetchAvailability(moment(formik.values.validFrom).format(), props.permit.clientId);
        }
    }, [formik.values.validTo]);

    const durationToTime = (duration: number) => {
        const time = convertSecondsToTime(duration);
        return `${time.days} day${time.days > 1 ? 's' : ''}${time.hours > 0 ? ` ${time.hours} hour${time.hours > 1 ? 's' : ''}` : ''}`;
    };

    const setDuration = (duration: number) => {
        formik.setFieldValue('endType', duration);
        formik.setFieldValue('duration', duration);
        if (formik.values.type === 'FIXED') {
            formik.setFieldValue('validTo', moment(formik.values.validFrom).add(duration, 'second').toDate());
        }
    };

    const setCustomDaysDuration = (newDayDuration: string) => {
        const hoursAsSeconds = convertSecondsToTime(formik.values.duration ?? 0).hours * SECONDS_IN_HOUR;
        const newDayAsSeconds = (parseInt(newDayDuration, 10) ? parseInt(newDayDuration, 10) : 0) * DURATION_ONE_DAY;
        formik.setFieldValue('duration', newDayAsSeconds + hoursAsSeconds);
        if (formik.values.type === 'FIXED') {
            formik.setFieldValue('validTo', moment(formik.values.validFrom).add(newDayDuration, 'days').toDate());
        }
    };

    const setCustomHoursDuration = (newHoursDuration: string) => {
        const newHoursAsSeconds = (parseInt(newHoursDuration, 10) ? parseInt(newHoursDuration, 10) : 0) * SECONDS_IN_HOUR;
        const daysAsSeconds = convertSecondsToTime(formik.values.duration ?? 0).days * DURATION_ONE_DAY;
        formik.setFieldValue('duration', daysAsSeconds + newHoursAsSeconds);
        if (formik.values.type === 'FIXED') {
            formik.setFieldValue('validTo', moment(formik.values.validFrom).add(newHoursDuration, 'hours').toDate());
        }
    };

    return (
        <div>
            <SettingsPopup
                show={showDurationPopup}
                permit={props.permit}
                onClose={() => setShowDurationPopup(false)}
                onDurationPresetEdit={props.onDurationPresetEdit}
            />
            <>
                <Label>Booking ends:</Label>
                <div className="select-buttons-container">
                    <Button
                        type="button"
                        color={formik.values.endType === 'SELECTED' ? 'primary-green' : 'secondary'}
                        disabled={formik.values.bookingId !== undefined && formik.values.type === 'ENTRY' && formik.values.endType !== 'SELECTED' &&
                            !((formik.values.validWasUsed && formik.values.status === 'USED') || formik.values.status === 'IN_USE')}
                        onClick={() => { setDuration(0); formik.setFieldValue('endType', 'SELECTED'); }}
                    >
                        Selected date
                    </Button>
                    <p className="or-after-text">or after</p>
                    {durationsButtons.map((duration) => {
                        return (
                            <Button
                                key={duration}
                                type="button"
                                color={formik.values.endType === duration ? 'primary-green' : 'secondary'}
                                disabled={formik.values.bookingId !== undefined && formik.values.type === 'ENTRY' &&
                                    (formik.values.duration === undefined || formik.values.duration === 0)}
                                onClick={() => setDuration(duration)}
                            >
                                {durationToTime(duration)}
                            </Button>
                        );
                    })}
                    <Button
                        type="button"
                        color={formik.values.endType === 'CUSTOM' ? 'primary-green' : 'secondary'}
                        disabled={formik.values.bookingId !== undefined && formik.values.type === 'ENTRY' && (formik.values.duration === undefined || formik.values.duration === 0)}
                        onClick={() => formik.setFieldValue('endType', 'CUSTOM')}
                    >
                        Custom
                    </Button>
                    {(formik.values.bookingId === undefined || formik.values.type === 'FIXED' ||
                            (formik.values.type === 'ENTRY' && formik.values.duration !== undefined && formik.values.duration !== 0)) &&
                        (
                            <div className="edit-duration-preset" onClick={() => setShowDurationPopup(true)}>
                                <Icon icon="edit_purple" />
                            </div>
                        )
                    }

                </div>
            </>
            {formik.values.endType === 'SELECTED' &&
                (
                    <FormGroup>
                        <Spacer size="sm" />
                        <Label htmlFor="validTo">Valid to:</Label>
                        <FormikDatePicker
                            name="validTo"
                            options={{
                                minDate: formik.values.validFrom,
                                maxDate: undefined,
                                altInput: true,
                                altFormat: 'd.m.Y H:i',
                                dateFormat: 'd.m.Y H:i',
                                enableTime: true,
                                time_24hr: true,
                            }}
                        />
                    </FormGroup>
                )
            }
            {formik.values.endType === 'CUSTOM' &&
                (
                    <FormGroup>
                        <Spacer size="sm" />
                        <div className="custom-duration-container">
                            <div>
                                <Label htmlFor="days">Days:</Label>
                                <Input
                                    id="days"
                                    value={convertSecondsToTime(formik.values.duration ?? 0).days}
                                    type="number"
                                    min={0}
                                    onChange={(event) => setCustomDaysDuration(event.target.value)}
                                />
                            </div>
                            <Spacer size="sm" />
                            <div>
                                <Label htmlFor="hours">Hours:</Label>
                                <Input
                                    id="hours"
                                    type="number"
                                    max={23}
                                    min={0}
                                    value={convertSecondsToTime(formik.values.duration ?? 0).hours}
                                    onChange={(event) => setCustomHoursDuration(event.target.value)}
                                />
                            </div>
                        </div>
                    </FormGroup>
                )
            }
            <FormikErrorMessage name="endType" before={<Spacer size="xs" />} />
        </div>
    );
};
