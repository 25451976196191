import * as React from 'react';
import {Booking, BookingAvailabilityState, CreateOrEditBookingForm, Permit} from '../../types';
import BookingForm from '../../components/bookingForm';
import {editBooking} from '../../../../services/http';

interface EditBookingProps {
    permit: Permit;
    goBack: (selectedPermit: Permit) => void;
    booking: Booking;
    bookingsAvailability: BookingAvailabilityState;
    fetchAvailability: (date: string, clientId: string) => void;
    onError: () => void;
}

function EditBooking(props: EditBookingProps) {
    const [permit, setPermit] = React.useState<Permit>(props.permit);

    const initialValues: CreateOrEditBookingForm = {
        bookingId: props.booking.bookingId,
        clientId: props.permit.clientId,
        licensePlateNumber: props.booking.licensePlateNumber,
        comment: props.booking.comment ?? '',
        type: props.booking.type,
        duration: props.booking.duration ? parseInt(props.booking.duration, 10) : undefined,
        validFrom: props.booking.validFrom !== undefined ? new Date(props.booking.validFrom) : undefined,
        validTo: props.booking.validTo !== undefined ? new Date(props.booking.validTo) : undefined,
        endType: getEndType(props.booking),
        status: props.booking.status,
        validWasUsed: props.booking.validWasUsed,
    };

    function getEndType(booking: Booking) {
        return booking.duration ? permit.durations.some((savedDuration) => parseInt(booking.duration, 10) === savedDuration) ?
            parseInt(booking.duration, 10) : 'CUSTOM' : 'SELECTED';
    }

    function onDurationPresetEdit(durations: number[]) {
        setPermit((prevState) => ({
            ...prevState,
            durations,
        }));
    }

    return (
        <BookingForm
            initialValues={initialValues}
            onDurationPresetEdit={onDurationPresetEdit}
            onSubmit={editBooking}
            goBack={() => props.goBack(permit)}
            permit={permit}
            bookingsAvailability={props.bookingsAvailability}
            fetchAvailability={props.fetchAvailability}
        />
    );
}

export default EditBooking;
